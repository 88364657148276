import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {
        path: "/",
        component: () => import('@/views/home/Home.vue') 
    },
    {
        path: "/sobre",
        component: () => import('@/views/aboutUs/AboutUs.vue') 
    },
    {
        path: "/servico",
        component: () => import('@/views/service/Services.vue') 
    },
    {
        path: "/contrato",
        component: () => import('@/views/contract/Contract.vue')
    },
    {
        path: "/contrate-agora",
        component: () => import('@/views/contractNow/ContractNow.vue')
    },
    {
        path: "/contato",
        component: () => import('@/views/contact/Contact.vue')
    },
    {
        path: "/perguntas-frequentes",
        component: () => import('@/views/commonQuestions/CommonQuestions.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router;