import { createApp } from 'vue'
import App from './App.vue'
import router from "./router.js"

import maska from "maska"

const Vue = createApp(App);
Vue.use(router);
Vue.use(maska);
Vue.mount('#app')
