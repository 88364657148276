<template>
  <header class="">
    <nav
      class="
        navbar navbar-expand-lg
        bg-light
        size-navbar
        animation-minHeader
        fontNeoSansStdRegular
      "
    >
      <div
        id="body-header"
        class="container-fluid d-flex justify-content-start"
      >
        <Transition name="appear" mode="out-in">
          <router-link
            to="/"
            id="logo-makroplano-min"
            v-if="minHeader"
          ></router-link>
          <router-link to="/" id="logo-makroplano" v-else></router-link>
        </Transition>
        <div class="mx-auto" id="navbarSupportedContent">
          <div class="d-flex mx-5">
            <ul class="menu-desktop navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item mx-2 mt-2 text-center">
                <router-link
                  class="
                    text-decoration-none
                    link-blue
                    animation-link-menu animation-link-menu-desktop
                  "
                  to="/"
                  exact-active-class="exact-active-desktop"
                  >Início</router-link
                >
              </li>
              <li class="nav-item mx-2 mt-2 text-center">
                <router-link
                  class="
                    text-decoration-none
                    link-blue
                    animation-link-menu animation-link-menu-desktop
                  "
                  to="/sobre"
                  exact-active-class="exact-active-desktop"
                  >Sobre nós</router-link
                >
              </li>
              <li class="nav-item mx-2 mt-2 text-center">
                <router-link
                  class="
                    text-decoration-none
                    link-blue
                    animation-link-menu animation-link-menu-desktop
                  "
                  to="/servico"
                  exact-active-class="exact-active-desktop"
                  >Serviços</router-link
                >
              </li>
              <li class="nav-item mx-2 mt-2 text-center">
                <router-link
                  class="
                    text-decoration-none
                    link-blue
                    animation-link-menu animation-link-menu-desktop
                  "
                  to="/contrato"
                  exact-active-class="exact-active-desktop"
                  >Contrato</router-link
                >
              </li>
              <li class="nav-item dropdown text-center">
                <a
                  class="
                    nav-link
                    dropdown-toggle
                    text-decoration-none
                    link-blue
                    animation-link-menu animation-link-menu-desktop
                  "
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                  id="btn-regulamentacoes"
                  >Regulamentações</a
                >
                <ul class="dropdown-menu">
                  <li class="nav-item mb-2 p-2 border-bottom">
                    <a
                      href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13261.htm"
                      target="_blank"
                      rel="external"
                      title="Lei Federal 13.251 - Makroplano"
                      class="text-decoration-none link-blue"
                      >Lei Federal 13.261</a
                    >
                  </li>
                  <li class="nav-item mb-2 p-2 border-bottom">
                    <a
                      href="https://leismunicipais.com.br/a/pr/c/curitiba/lei-ordinaria/2002/1059/10595/lei-ordinaria-n-10595-2002-dispoe-sobre-o-servico-funerario-no-municipio-de-curitiba-revogando-as-leis-n-2819-66-e-5-000-74"
                      target="_blank"
                      rel="external"
                      title="Lei Municipal 10.595 Curitiba - Makroplano"
                      class="text-decoration-none link-blue"
                      >Lei Municipal 10.595 Curitiba</a
                    >
                  </li>
                  <li class="nav-item mb-2 p-2 border-bottom">
                    <a
                      href="http://obituarios.curitiba.pr.gov.br/"
                      target="_blank"
                      rel="external"
                      title="Serviço Funerário Municipal Curitiba"
                      class="text-decoration-none link-blue"
                      >Serviço Funerário Municipal Curitiba</a
                    >
                  </li>

                  <li class="nav-item p-2">
                    <a
                      class="
                        text-decoration-none
                        link-blue
                        animation-link-menu animation-link-menu-desktop
                      "
                      :href="'/files/AcordaoMPPRDefesaConsumidorXplanosdeluto.pdf'"
                      target="blank"
                      >Acordão</a
                    >
                  </li>
                </ul>
              </li>

              <li class="nav-item mx-2 mt-2 text-center">
                <router-link
                  class="
                    text-decoration-none
                    link-blue
                    animation-link-menu animation-link-menu-desktop
                  "
                  to="/contrate-agora"
                  exact-active-class="exact-active-desktop"
                  >Quero contratar
                </router-link>
              </li>
              <li class="nav-item mx-2 mt-2 text-center">
                <router-link
                  class="
                    text-decoration-none
                    link-blue
                    animation-link-menu animation-link-menu-desktop
                  "
                  to="/contato"
                  exact-active-class="exact-active-desktop"
                  >Contato</router-link
                >
              </li>
              <li class="nav-item mx-2 mt-2 text-center">
                <router-link
                  class="
                    text-decoration-none
                    link-blue
                    animation-link-menu animation-link-menu-desktop
                  "
                  to="/perguntas-frequentes"
                  exact-active-class="exact-active-desktop"
                  >Perguntas frequentes</router-link
                >
              </li>
            </ul>
            <div class="mx-4 menu-mobile d-none" id="link-redes-makroplano">
              <a
                href="https://api.whatsapp.com/send/?phone=5541988042776&text&type=phone_number&app_absent=0"
                target="_blank"
                title="Whatsapp Makroplano"
                class="link-blue fs-4 mx-2"
                ><i class="bi bi-whatsapp"></i
              ></a>
              <a
                href="https://www.facebook.com/makroplano/"
                title="Facebook Makroplano"
                target="_blank"
                class="link-blue fs-4 mx-2"
                ><i class="bi bi-facebook"></i
              ></a>
              <a
                href="https://www.instagram.com/makroplano/"
                title="Instagram Makroplano"
                target="_blank"
                class="link-blue fs-4 mx-2"
                ><i class="bi bi-instagram"></i
              ></a>
            </div>
            <a
              id="iconMenuHeader"
              class="
                menu-mobile
                nav-link
                link-blue
                fw-bold
                align-items-center
                mt-1
                d-none
              "
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <span class="mb-1">Menu</span> <i class="bi bi-list h4"></i>
            </a>
          </div>
        </div>
      </div>
    </nav>

    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header d-flex justify-content-center m-0 p-0">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">
          <div class="w-100 m-0 p-0 d-flex justify-content-center">
            <img
              width="200"
              src="./../../assets/logo_makroplano01-removebg-preview.png"
              alt="Logo Makroplano"
            />
          </div>

          <button
            type="button"
            class="btn-close btn-sm btn-close-custom"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </h5>
      </div>
      <hr />
      <div class="offcanvas-body d-flex flex-column justify-content-between">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item mb-2">
            <router-link
              class="text-decoration-none link-blue fs-4 animation-link-menu"
              to="/"
              @click="closeModal()"
              exact-active-class="exact-active"
              >Início</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              class="text-decoration-none link-blue fs-4 animation-link-menu"
              to="/sobre"
              @click="closeModal()"
              exact-active-class="exact-active"
              >Sobre Nós</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              class="text-decoration-none link-blue fs-4 animation-link-menu"
              to="/servico"
              @click="closeModal()"
              exact-active-class="exact-active"
              >Serviços</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              class="text-decoration-none link-blue fs-4 animation-link-menu"
              to="/contrato"
              @click="closeModal()"
              exact-active-class="exact-active"
              >Contrato</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <a
              class="text-decoration-none link-blue fs-4 animation-link-menu"
              data-bs-toggle="collapse"
              href="#regulamentacoesItens"
              role="button"
              aria-expanded="false"
              aria-controls="regulamentacoesItens"
              id="btn-regulamentacoes"
              >Regulamentações <i class="bi bi-caret-down-fill"></i
            ></a>

            <div class="collapse" id="regulamentacoesItens">
              <div class="card card-body">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item mb-2 border-bottom">
                    <a
                      href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/lei/l13261.htm"
                      target="_blank"
                      title="Lei Federal 13.251 - Makroplano"
                      class="
                        text-decoration-none
                        link-blue
                        fs-6
                        animation-link-menu
                      "
                      @click="closeModalAndRegulamentos()"
                      >Lei Federal 13.261</a
                    >
                  </li>
                  <li class="nav-item mb-2 border-bottom">
                    <a
                      href="https://leismunicipais.com.br/a/pr/c/curitiba/lei-ordinaria/2002/1059/10595/lei-ordinaria-n-10595-2002-dispoe-sobre-o-servico-funerario-no-municipio-de-curitiba-revogando-as-leis-n-2819-66-e-5-000-74"
                      target="_blank"
                      title="Lei Municipal 10.595 Curitiba - Makroplano"
                      class="
                        text-decoration-none
                        link-blue
                        fs-6
                        animation-link-menu
                      "
                      @click="closeModalAndRegulamentos()"
                      >Lei Municipal 10.595 Curitiba</a
                    >
                  </li>
                  <li class="nav-item mb-2 border-bottom">
                    <a
                      href="http://obituarios.curitiba.pr.gov.br/"
                      target="_blank"
                      title="Serviço Funerário Municipal Curitiba"
                      class="
                        text-decoration-none
                        link-blue
                        fs-6
                        animation-link-menu
                      "
                      @click="closeModalAndRegulamentos()"
                      >Serviço Funerário Municipal Curitiba</a
                    >
                  </li>
                  <li class="nav-item border-bottom">
                    <a
                      class="
                        text-decoration-none
                        link-blue
                        animation-link-menu animation-link-menu-desktop
                      "
                      :href="'/files/AcordaoMPPRDefesaConsumidorXplanosdeluto.pdf'"
                      target="blank"
                      >Acordão</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="nav-item mb-2">
            <router-link
              class="text-decoration-none link-blue fs-4 animation-link-menu"
              to="/contrate-agora"
              @click="closeModal()"
              exact-active-class="exact-active"
              >Contrate Agora</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              class="text-decoration-none link-blue fs-4 animation-link-menu"
              to="/contato"
              @click="closeModal()"
              exact-active-class="exact-active"
              >Contato</router-link
            >
          </li>
          <li class="nav-item mb-2">
            <router-link
              class="text-decoration-none link-blue fs-4 animation-link-menu"
              to="/perguntas-frequentes"
              @click="closeModal()"
              exact-active-class="exact-active"
              >Perguntas Frequentes</router-link
            >
          </li>
        </ul>

        <div class="w-100 text-center">
          <div class="m-3">
            <a
              href="https://api.whatsapp.com/send/?phone=5541988042776&text&type=phone_number&app_absent=0"
              target="_blank"
              title="Whatsapp Makroplano"
              class="link-blue fs-4 mx-2"
              ><i class="bi bi-whatsapp"></i
            ></a>
            <a
              href="https://www.facebook.com/makroplano/"
              title="Facebook Makroplano"
              target="_blank"
              class="link-blue fs-4 mx-2"
              ><i class="bi bi-facebook"></i
            ></a>
            <a
              href="https://www.instagram.com/makroplano/"
              title="Instagram Makroplano"
              target="_blank"
              class="link-blue fs-4 mx-2"
              ><i class="bi bi-instagram"></i
            ></a>
          </div>
          <h4>
            <span class="link-yellow">#</span>
            <span class="link-blue">SEMPRE</span
            ><span class="link-yellow">COM</span
            ><span class="link-blue">VOCÊ</span>
          </h4>
          <div>
            Desenvolvido por
            <a
              href="http://www.devamp.com.br"
              class="text-secondary text-decoration-none"
              target="_blank"
              >DEV_AMP</a
            >
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",

  props: ["minHeader"],

  watch: {
    minHeader(newValue) {
      const el = document.querySelector(".size-navbar");
      if (newValue) {
        el.style = "height: 80px !important";
        return;
      }

      return (el.style = "height: 160px !important");
    },
  },

  methods: {
    closeModal() {
      document.querySelector(".btn-close").click();
    },

    closeModalAndRegulamentos() {
      document.querySelector(".btn-close").click();
      document.querySelector("#btn-regulamentacoes").click();
    },
  },
};
</script>

<style scoped>
header {
  border-bottom: 3px solid var(--blueMakroplano);
}
#logo-makroplano-min {
  background-image: url("./../../assets/logo-padrao-makroplano.png");
  background-size: cover;
  background-position: center;
}

#logo-makroplano {
  background-image: url("./../../assets/logo_makroplano01-removebg-preview.png");
  background-size: cover;
  background-position: center;
}

#logo-makroplano,
#logo-makroplano-min {
  width: 400px !important;
  height: 220px !important;
}

#logo-makroplano-sm {
  display: none;
  margin-top: -10px;
  width: 200px;
  height: 100px !important;
}

.size-navbar {
  height: 160px !important;
  transition: 0.1s !important;
}

.offcanvas,
.collapse {
  transition: all 0.3s !important;
}

.animation-link-menu {
  transition: all 0.3s !important;
}

.animation-link-menu-desktop:hover {
  border-bottom: 2px solid var(--blueMakroplano) !important;
}

.exact-active-desktop {
  border-bottom: 2px solid var(--blueMakroplano) !important;
  color: var(--yellowMakroplano);
}

.exact-active {
  padding-left: 10px !important;
  border-left: 2px solid var(--blueMakroplano) !important;
  color: var(--yellowMakroplano);
}

.btn-close-custom {
  position: absolute;
  top: 10px;
  right: 5px !important;
}

@media screen and (max-width: 1020px) {
  .menu-desktop {
    display: none !important;
  }

  .menu-mobile {
    display: block !important;
  }
}

.appear-enter-active,
.appear-leave-active {
  transition: opacity 0.1s ease;
}

.appear-enter-from,
.appear-leave-to {
  opacity: 0;
}
</style>
