<template>
  <footer
    id="footer"
    class="
      w-100
      bg-main
      px-3
      py-2
      text-yellow
      d-flex
      justify-content-between
      align-items-center
      fontNeoSansStdRegular
    "
  >
    <div class="text-center">
      <div>
        <span>Politica de privacidade</span>
      </div>
      <div class="small">
        <span>Desenvolvido por</span>
        <a
          class="text-decoration-none link-yellow mx-1"
          href="http://www.devamp.com.br"
          target="blank"
          >DEV_AMP</a
        >
      </div>
    </div>

    <div class="d-flex flex-column align-items-center">
      <span class="text-center"
        >Rua 7 de Setembro, 141 - Centro - Pinhais/PR</span
      >
      <span>Todos os direitos reservados@{{ actualYear }}</span>
    </div>

    <div class="d-flex align-items-center">
      <a
        href="https://www.facebook.com/makroplano/"
        title="Facebook Makroplano"
        class="
          d-flex
          flex-column
          align-items-center
          mx-2
          text-decoration-none
          link-yellow
        "
        target="_blank"
      >
        <i class="bi bi-facebook"></i>
        <span>Facebook</span>
      </a>
      <a
        href="https://www.instagram.com/makroplano/"
        class="
          d-flex
          flex-column
          align-items-center
          mx-2
          text-decoration-none
          link-yellow
        "
        title="Instagran Makroplano"
        target="_blank"
      >
        <i class="bi bi-instagram"></i>
        <span>Instagram</span>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      actualYear: "",
    };
  },

  mounted() {
    this.actualYear = new Date().getFullYear();
  },
};
</script>

<style scoped>
#footer {
  height: 60px !important;
}

a:hover {
  color: #fff !important;
  transition: all linear 0.3s;
}

@media only screen and (max-width: 720px) {
  #footer {
    flex-direction: column-reverse !important;
    justify-content: center !important;
    height: auto !important;
  }
  #footer > div {
    margin-bottom: 7px;
  }
}
</style>