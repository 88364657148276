<template>
  <section class="main-system">
    <Transition name="appear" mode="out-in">
      <router-view></router-view>
    </Transition>
  </section>
</template>

<script>
export default {};
</script>

<style>
.main-system {
  margin-top: 160px;
}
.appear-enter-active,
.appear-leave-active {
  transition: opacity 0.5s ease;
}

.appear-enter-from,
.appear-leave-to {
  opacity: 0;
}
</style>