<template>
  <main class="w-100">
    <Header id="nav-makroplano" class="fixed-top" :minHeader="minHeader" />
    <Main />
    <Footer />
  </main>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import Main from "@/components/contentWebSite/Main.vue";

export default {
  name: "App",
  components: { Header, Footer, Main },

  data() {
    return {
      minHeader: false,
      scrollPosition: "",
    };
  },

  created() {
    this.scrollPosition = document.body.getBoundingClientRect().top;
    addEventListener("scroll", () => {
      this.scrollPosition = document.body.getBoundingClientRect().top;
    });
    console.log(this.scrollPosition);
  },

  watch: {
    scrollPosition(newValue) {
      if (newValue == 160 || newValue == 0) return (this.minHeader = false);

      return (this.minHeader = true);
    },
  },

  methods: {},
};
</script>

<style scoped>
</style>

<style src="@/appStyles.css" />
